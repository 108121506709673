import styled from 'styled-components';
import backgroundBiben from 'images/background-biben.svg';
import { Stack, media } from '@tymate/margaret';
import logo from 'images/biben-logo.svg';
import { fontStyles } from '../ui';

const Main = styled.div`
  ${media.tablet`
    display: grid;
    height: 100vh;
    grid-template-columns: 500px 1fr;
  `}
`;

const Sidebar = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => theme.white};

  z-index: 2;
`;

const RightPartStack = styled(Stack)`
  display: none;
  position: relative;

  ${media.tablet`
    display:flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.primary};
  `}

  ${media.desktop`
    display:block;
    background-color: ${({ theme }) => theme.primary};
  `}
`;

const TopStack = styled(Stack)`
  float: right;
  flex-direction: column-reverse;

  align-items: center;
  justify-content: center;

  ${media.desktop`
    padding-left: ${({ theme }) => theme.spacing(4)};
    flex-direction: row;
  `}
`;

const BackgroundText = styled.span`
  ${fontStyles.h1};
  color: ${({ theme }) => theme.backgroundText};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  position: absolute;
  top: 50%;

  ${media.desktop`
    padding: 0;
    position: static;
    font-size: 1.85vw;
    font-weight: 400;
    line-height: 40px;
  `}
`;

const Circle1 = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.pinkMid};
  border-left-style: none;
  width: 5vw;
  height: 10vw;
  border-radius: 0 5vw 5vw 0;
  position: absolute;
  bottom: 6vw;
  left: 0;
`;

const Circle2 = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.pinkMid};
  border-bottom-style: none;
  width: 25vw;
  height: 12.5vw;
  border-radius: 12.5vw 12.5vw 0 0;
  position: absolute;
  bottom: 0;
  left: -5vw;
  z-index: 1;
`;

const LoginImage = styled.img`
  width: 75%;
  height: auto;
`;

const Logo = styled.img`
  max-width: 500px;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

const AuthLayout = ({ children }) => {
  return (
    <Main>
      <Sidebar>
        <Stack size="full" alignX="center">
          <Logo src={logo} />
        </Stack>
        {children}
      </Sidebar>
      <RightPartStack size="full">
        <TopStack>
          <BackgroundText>
            Bienvenue sur votre espace copropriétaire
          </BackgroundText>
          <LoginImage src={backgroundBiben} />
        </TopStack>

        <Circle1 />
        <Circle2 />
      </RightPartStack>
    </Main>
  );
};

export default AuthLayout;
