import gql from 'graphql-tag';

export const GET_CONTRACT_FOLDERS = gql`
  query getContractFolders($after: String, $contractId: ID!) {
    contractFolders(contractId: $contractId, after: $after, first: 15) {
      edges {
        node {
          id
          createdAt
          displayName
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_CONTRACT_DOCUMENTS = gql`
  query getContractDocuments(
    $after: String
    $contractFolderId: ID!
    $contractId: ID!
  ) {
    contractDocuments(
      contractId: $contractId
      contractFolderId: $contractFolderId
      after: $after
    ) {
      edges {
        node {
          id
          displayName
          documentName
          fileUrl
        }
      }
    }
  }
`;

export const GET_COOWNERSHIP_FOLDERS = gql`
  query getCoownershipFolders($cursor: String, $placeId: ID!) {
    coownershipFolders(placeId: $placeId, after: $cursor, first: 15) {
      edges {
        node {
          id
          createdAt
          displayName
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_COOWNERSHIP_DOCUMENTS = gql`
  query getCoownershipDocuments($after: String, $coownershipFolderId: ID!) {
    coownershipDocuments(
      coownershipFolderId: $coownershipFolderId
      after: $after
    ) {
      edges {
        node {
          id
          displayName
          documentName
          fileUrl
        }
      }
    }
  }
`;
