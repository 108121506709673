export const API_BASE = process.env.REACT_APP_API_BASE_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const API_SCOPE = process.env.REACT_APP_API_SCOPE;
export const MESSAGING_API_BASE = process.env.REACT_APP_MESSAGING_API_BASE;
export const INTEGRATION_URL = process.env.REACT_APP_INTEGRATION_URL;
export const AV_WIDGET_TOKEN = process.env.REACT_APP_AV_WIDGET_TOKEN;
export const APPSIGNAL_API_KEY = process.env.REACT_APP_APPSIGNAL_API_KEY;
export const GOOGLE_TAG_MANAGER_ID =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
export const MAINTENANCE_ALERT = process.env.REACT_APP_MAINTENANCE_ALERT;
