import styled, { css } from 'styled-components';
import { fontStyles } from './theme';
import { ButtonReset, Stack } from '@tymate/margaret';

export const FormLabel = styled.label`
  ${fontStyles.bodySmall};
  font-weight: 500;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  ${({ hasError }) =>
    hasError &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(0.5)};
    `}
`;

export const FormFields = styled(Stack).attrs(({ gutterSize }) => ({
  direction: 'column',
  gutterSize: gutterSize || 2,
}))``;

export const InputIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ theme }) => theme.spacing(0.75)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textLight};
`;

export const PasswordButton = styled(InputIcon).attrs({
  as: ButtonReset,
})``;

export const Input = styled.input`
  padding: 0 ${({ theme }) => theme.spacing(0.75)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 4px;
  width: 100%;
  transition: border-color 100ms ease;
  background-color: #fff;
  min-height: 48px;
  display: flex;
  align-items: center;

  ${({ variant }) =>
    variant === 'small' &&
    css`
      ${fontStyles.bodySmall}
      min-height: 30px;
    `}

  ${({ inputVariant }) =>
    inputVariant === 'recipients' &&
    css`
      border: 0;
      background-color: ${({ theme }) => theme.white};
    `}

  svg {
    transition: transform 150ms ease;
  }

  button:focus &,
  &:not([disabled]):focus,
  &:not([disabled]):active {
    outline: none;
    border-color: ${({ theme }) => theme.primary};
  }

  ${({ variant, theme }) =>
    variant === 'active' &&
    `
      outline: none;
      border-color: ${theme.primary};

      svg {
        color: ${theme.primary};
        transform: rotate(180deg);
      }
    `}

  &:disabled {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.grey};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.grey};

      &:focus,
      &:active {
        border-color: ${({ theme }) => theme.separator} !important;
      }
    `}

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active,
      &:not([disabled]):focus {
        border: 1px solid ${({ theme }) => theme.error};
      }
    `}

  ${({ withIcon, theme }) =>
    withIcon &&
    `
      padding-right: ${theme.spacing(2.25)}
    `}
`;

export const ErrorMessageWrapper = styled.div`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.error};
  position: absolute;
  top: calc(100% + ${({ theme }) => theme.spacing(0.125)});
  line-height: 1;
`;

export const ErrorMessage = styled.div`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.error};
`;
