import styled from 'styled-components';
import { MdWarning, MdCheckCircle, MdCancel } from 'react-icons/md';
import { Stack } from '@tymate/margaret';

const ToastBodyWrapper = styled(Stack)`
  ${({ variant, theme }) =>
    Boolean(variant) &&
    `
      color: ${theme?.[variant]}
    `}
`;

export const ToastBody = ({ variant, children }) => (
  <ToastBodyWrapper gutterSize={0.5} alignY="center" variant={variant}>
    <Stack>
      {variant === 'success' && <MdCheckCircle />}
      {variant === 'warning' && <MdWarning />}
      {variant === 'error' && <MdCancel />}
    </Stack>
    <div>{children}</div>
  </ToastBodyWrapper>
);

export default ToastBody;
