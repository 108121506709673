import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SidebarNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.textDark};
  text-decoration: none;
  display: block;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  border-radius: 4px;
  text-align: left;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  &:hover > div > div:nth-child(1),
  &.active > div > div:nth-child(1) {
    transition: background-color 150ms ease, color 150ms ease;
    background-color: ${({ theme }) => theme.pink};
  }

  &:hover > div > div > img,
  &.active > div > div > img {
    transition: filter 150ms ease;
    filter: ${({ theme }) => theme.filterWhite};
  }

  ${({ isSection }) =>
    isSection &&
    css`
      &:hover,
      &.active {
        transition: background-color 150ms ease, font-weight 150ms ease;
        font-weight: bold;
        color: ${({ theme }) => theme.pink};
        background-color: ${({ theme }) => theme.greyLighter};
      }
    `};
`;
