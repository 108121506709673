import { StrictMode, Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MargaretProvider } from '@tymate/margaret';
import AppProvider from 'providers/AppProvider';
import AuthProvider from 'providers/AuthProvider';
import ClientProvider from 'providers/ClientProvider';
import ToastProvider from 'providers/ToastProvider';
import { theme, GlobalStyles } from 'ui';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';

const App = lazy(() => import('./App'));
const Callback = lazy(() => import('containers/Auth/Callback'));
const ForgotPassword = lazy(() => import('containers/Auth/ForgotPassword'));
const SignIn = lazy(() => import('containers/Auth/SignIn'));

const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<div />}>
      <MargaretProvider theme={theme}>
        <GlobalStyles />
        <BrowserRouter>
          <ToastProvider>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <ClientProvider>
                  <Routes>
                    <Route element={<Callback />} path="/callback/:callback" />
                    <Route
                      element={<ForgotPassword />}
                      path="/mot-de-passe-oublie"
                    />
                    <Route element={<SignIn />} path="/connexion" />
                    <Route
                      path="*"
                      element={
                        <AppProvider>
                          <App />
                        </AppProvider>
                      }
                    />
                  </Routes>
                </ClientProvider>
              </QueryClientProvider>
            </AuthProvider>
          </ToastProvider>
        </BrowserRouter>
      </MargaretProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();

reportWebVitals();
