import { useState, createContext, useContext } from 'react';
import { createClient, dedupExchange, fetchExchange, Provider } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { API_BASE } from '../constants';
import { cacheExchange } from '@urql/exchange-graphcache';
import { relayPagination } from '@urql/exchange-graphcache/extras';
import { authExchange } from '@urql/exchange-auth';
import { makeOperation } from '@urql/core';
import { useAuth } from './AuthProvider';
import { useDeepCompareEffect } from 'react-use';
import { refreshToken } from 'api';

export const ClientContext = createContext();

export const useClient = () => {
  const context = useContext(ClientContext);

  if (context === undefined) {
    throw new Error(`useClient must be used within an ClientProvider`);
  }

  return context;
};

const cache = cacheExchange({
  resolvers: {
    Query: {
      allConversation: relayPagination(),
      allIdentities: relayPagination(),
      conversationMessages: relayPagination(),
      sageContractBalances: relayPagination(),
      placeUnrelatedMaintenanceContractCategories: relayPagination(),
      coownershipFolders: relayPagination(),
      contractFolders: relayPagination(),
      maintenanceContractCategories: relayPagination(),
      sageAccountCodes: relayPagination(),
    },
  },
});

const makeClient = () =>
  createClient({
    url: `${API_BASE}/graphql`,
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      cache,
      authExchange({
        addAuthToOperation: ({ authState, operation }) => {
          if (!Boolean(authState?.token)) {
            return operation;
          }

          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${
                  localStorage.getItem('impersonationToken') ||
                  localStorage.getItem('accessToken')
                }`,
              },
            },
          });
        },

        willAuthError: ({ authState }) => !authState,

        didAuthError: ({ error }) =>
          error?.response?.statusText === 'Unauthorized' ||
          error?.response?.status === 401,

        getAuth: async ({ authState }) => {
          if (!authState) {
            const token = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');

            if (Boolean(token) && Boolean(refreshToken)) {
              return { token, refreshToken };
            }

            return null;
          }

          try {
            const { data } = await refreshToken({
              refreshToken: authState?.refreshToken,
            });

            if (Boolean(data?.accessToken)) {
              localStorage.setItem('accessToken', data.accessToken);
              localStorage.setItem('refreshToken', data.refreshToken);

              return {
                token: data.accessToken,
                refreshToken: data.refreshToken,
              };
            }
          } catch (err) {}

          localStorage.clear();

          return null;
        },
      }),
      fetchExchange,
    ],
  });

const ClientProvider = ({ children }) => {
  const [client, setClient] = useState(makeClient());
  const { isLoggedIn } = useAuth();

  const resetClient = () => {
    setClient(makeClient());
  };

  useDeepCompareEffect(() => {
    resetClient();
  }, [{ isLoggedIn }]);

  return (
    <ClientContext.Provider
      value={{
        resetClient,
        client,
      }}
    >
      <Provider value={client}>{children}</Provider>
    </ClientContext.Provider>
  );
};

export default ClientProvider;
