import gql from 'graphql-tag';

export const GET_PLACE_BUDGETS = gql`
  query sagePlaceBudgets($placeId: ID!, $contractId: ID!) {
    sagePlaceBudgets(placeId: $placeId, contractId: $contractId) {
      cles {
        libelle
        detail {
          codeExercice
          enCours
          montantVote
          montantConsomme
          budgets {
            codeComptable
            libelle
            montantVote
            montantConsomme
            montantCoproprietaire
          }
        }
      }
    }
  }
`;

export const GET_SAGE_FISCAL_YEAR_BUDGETS = gql`
  query sageFiscalYearBudgets($placeId: ID!, $contractId: ID!) {
    sageFiscalYearBudgets(placeId: $placeId, contractId: $contractId) {
      edges {
        node {
          enCours
          code
          montantVote
          montantConsomme
          cles {
            edges {
              node {
                code
                libelle
                montantConsomme
                montantVote
                budgets {
                  edges {
                    node {
                      codeComptable
                      libelle
                      montantConsomme
                      montantCoproprietaire
                      montantVote
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SAGE_FISCAL_YEARS = gql`
  query sageFiscalYears($placeId: ID!) {
    sageFiscalYears(placeId: $placeId) {
      nodes {
        dateDebut
        dateFin
        enCours
      }
    }
  }
`;

export const GET_REPARTITION_KEYS = gql`
  query repartitionKeys($placeId: ID!) {
    repartitionKeys(placeId: $placeId, order: { by: letter, direction: asc }) {
      edges {
        node {
          id
          letter
          displayName
          description
        }
      }
    }
  }
`;

export const GET_SAGE_ACCOUNT_CODES = gql`
  query sageAccountCodes($placeId: ID!, $cursor: String) {
    sageAccountCodes(placeId: $placeId, after: $cursor) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          code
          libelle
          typage
        }
      }
    }
  }
`;

export const GET_SAGE_PLACE_BALANCE = gql`
  query sagePlaceBalance($placeId: ID!) {
    sagePlaceBalance(placeId: $placeId) {
      soldes {
        libelle
        solde
        comptes {
          codeCompte
        }
      }
    }
  }
`;

export const GET_COOWNERS_BALANCE = gql`
  query sageContractBalance($placeId: ID!, $cursor: String) {
    sageContractBalances(placeId: $placeId, after: $cursor, first: 20) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          coproprietaire
          nomPrenom
          immeuble
          dateDernierPrelevement
          soldeActuel
          contract {
            id
            reminderAlerts {
              edges {
                node {
                  reminderDate
                  reminderLevel
                  id
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_TRANSACTION_INPUT = gql`
  mutation createTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      transaction {
        id
        amount
      }
      errors {
        key
        message
      }
    }
  }
`;

export const GET_SAGE_PROVIDERS = gql`
  query sageProviders($placeId: ID!, $siret: String) {
    sageProviders(placeId: $placeId, siret: $siret) {
      edges {
        node {
          bic
          email
          iban
          matricule
          siret
          raisonSociale
        }
      }
    }
  }
`;

export const CHECK_IBAN = gql`
  mutation checkIban($input: CheckIbanInput!) {
    checkIban(input: $input) {
      valid
      errors {
        key
        message
      }
    }
  }
`;

export const GET_PLACE_ACCOUNT_ENTRIES = gql`
  query sagePlaceAccountEntries(
    $placeId: ID!
    $page: Int!
    $accountCodes: [Int!]
    $providerMatricules: [String!]
    $kinds: [String!]
    $fromDate: ISO8601Date
    $toDate: ISO8601Date
    $paymentStatus: SagePlaceAccountEntryPaymentStatus
  ) {
    sagePlaceAccountEntries(
      order: desc
      page: $page
      perPage: 20
      placeId: $placeId
      accountCodes: $accountCodes
      providerMatricules: $providerMatricules
      kinds: $kinds
      fromDate: $fromDate
      toDate: $toDate
      paymentStatus: $paymentStatus
    ) {
      totalPages
      edges {
        node {
          id
          compte
          credit
          date
          debit
          fichier
          libelle
          matriculeFournisseur
          montant
          tiers
          type
          typePaiement
        }
      }
    }
  }
`;

export const GET_SAGE_VOTED_ESTIMATED_BUDGETS = gql`
  query sageVotedEstimatedBudgets($placeId: ID!) {
    sageVotedEstimatedBudgets(placeId: $placeId) {
      avanceTravaux
      avanceTresorerie
      prevoyance
    }
  }
`;

export const GET_SAGE_WRITINGS = gql`
  query sageWritings($placeId: ID!, $oidEcriture: String!, $cursor: String) {
    sageWritings(placeId: $placeId, oidEcriture: $oidEcriture, after: $cursor) {
      edges {
        node {
          id
          libelle
          montant
          date
          fichier
          debit
          credit
          type
          compte
          identifiantCoproprietaire
          typePaiement
          tiers
        }
      }
    }
  }
`;
