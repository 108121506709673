import { post } from './api';
import { API_SCOPE, CLIENT_ID } from '../constants';

export const signIn = ({ email, password }) =>
  post(`/v1/oauth/token`, {
    username: email,
    password,
    clientId: CLIENT_ID,
    scope: API_SCOPE,
    grantType: 'password',
  });

export const refreshToken = payload =>
  post(`/v1/oauth/token`, {
    refreshToken: localStorage.getItem('refreshToken'),
    clientId: CLIENT_ID,
    scope: API_SCOPE,
    grantType: 'refresh_token',
  });

export const checkMail = async ({ email }) =>
  post(`/v1/users/mail_check`, {
    client_id: CLIENT_ID,
    user: { email },
  });

export const sendForgottenEmailPassword = ({ email }) =>
  post('/v1/users/password', {
    user: { email, sergic_offer: 'DIR' },
    client_id: CLIENT_ID,
  });

export const acceptInvite = ({ email, password, invitationToken }) =>
  post(`/v1/users/set_password`, {
    user: { password },
    invitation_token: invitationToken,
    client_id: CLIENT_ID,
  });

export const resetPassword = payload =>
  post(`/v1/users/reset`, {
    ...payload,
    client_id: CLIENT_ID,
  });

export const impersonateUser = userId =>
  post(`/v1/admin/token`, {
    clientId: CLIENT_ID,
    userId,
  });
