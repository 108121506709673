import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }

  return context;
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState(
    Boolean(localStorage) ? localStorage.getItem('accessToken') : null,
  );
  const [impersonationToken, setImpersonationToken] = useState(
    localStorage.getItem('impersonationToken'),
  );

  const handleReceiveToken = ({ accessToken, refreshToken }) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    setAccessToken(accessToken);
    navigate('/');
  };

  const handleReceiveImpersonationToken = token => {
    localStorage.setItem('impersonationToken', token);
    window.location.reload();
  };

  const handleReset = () => {
    localStorage.clear();
    setAccessToken(null);
  };

  const handleLogout = () => {
    if (Boolean(impersonationToken)) {
      setImpersonationToken(null);
      localStorage.removeItem('impersonationToken');
      window.location.reload();
      return;
    }

    handleReset();
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: Boolean(accessToken),
        accessToken,
        onReceiveToken: handleReceiveToken,
        isImpersonating: Boolean(impersonationToken),
        onReceiveImpersonationToken: handleReceiveImpersonationToken,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
