import { createContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useMargaret } from '@tymate/margaret';
import { useLocation } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { useAuth } from './AuthProvider';
import AuthLayout from 'components/AuthLayout';
import { fontSize } from 'ui';
import { find, flatten } from 'lodash';

const TextRedirection = styled.p`
  ${fontSize.bodyLarge}
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  font-weight: 500;
`;

export const AppContext = createContext();

const GET_CURRENT_TERMS = gql`
  query getCurrentTerms {
    currentTerm {
      id
      version
      body
    }
  }
`;

const GET_USER = gql`
  query getUser {
    me {
      id
      isSergicPartner
      isSergicPartnerAdmin
      avatarUrl
      firstName
      lastName
      termAccepted
      places(filter: { integrationState: { eq: done } }) {
        edges {
          node {
            id
            displayName
            pictureUrl
            sergicIdFull
            canCreateDecision
            currentUserContracts {
              edges {
                node {
                  id
                  displayName
                  moneyOrderState
                  moneyOrder {
                    id
                    yousignState
                    rib {
                      id
                      iban
                      bic
                    }
                    paymentType
                  }
                  contractRepartitionKeys {
                    edges {
                      node {
                        id
                        letter
                        fees
                        repartitionKey {
                          feesTotal
                        }
                      }
                    }
                  }
                  customerReferenceNumber
                  identity {
                    id
                    firstName
                    lastName
                    email
                    phoneNumber
                    title
                    displayName
                    phoneChecked
                    avatar {
                      id
                      fileUrl
                      displayName
                    }
                    address {
                      id
                      streetNumber
                      street
                      zipCode
                      city
                    }
                  }
                  place {
                    id
                    displayName
                    pictureUrl
                    ongoingFiscalYear {
                      id
                      endDate
                      startDate
                    }
                    canCreateDecision
                    president {
                      id
                      displayName
                    }
                    caretaker {
                      id
                      displayName
                      phoneNumber
                      title
                      avatar {
                        id
                        fileUrl
                      }
                    }
                    constructionYear
                    dtg
                    diagnoses {
                      edges {
                        node {
                          id
                          status
                          diagComment
                          completionDate
                          diagnostician
                          energyLabel
                          gesLetter
                          isPresentAtDate
                          totalProcessing
                          hasNextInspection
                          diagnosisCategory {
                            id
                            name
                            mandatory
                            comment
                            isDpe
                            recommendation
                            renewAfterYears
                          }
                          attachment {
                            fileUrl
                            sourceFileName
                            sourceFileSize
                            sourceContentType
                            displayName
                          }
                          createdAt
                          updatedAt
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_DEFAULT_IDENTITY = gql`
  query getDefaultIdentity($placeId: ID!) {
    me {
      id
      displayPlaceRole(placeId: $placeId)
      topPlaceRole(placeId: $placeId)
    }
  }
`;

const AppProvider = ({ children }) => {
  const { mainNavIsExpanded, collapseMainNav } = useMargaret();
  const { isLoggedIn, isImpersonating } = useAuth();
  const { key } = useLocation();

  const [selectedContract, setSelectedContract] = useState();

  const [
    { data: userData, fetching: isFetchingUser, stale: isLoadingUser, error },
    refetchUser,
  ] = useQuery({
    query: GET_USER,
    requestPolicy: 'cache-and-network',
  });

  const userAuthError = error?.graphQLErrors?.[0]?.extensions?.details;

  const me = userData?.me;

  const defaultContract =
    me?.places?.edges?.[0]?.node?.currentUserContracts?.edges?.[0]?.node;

  const selectedOrDefaultContract = selectedContract || defaultContract;
  const selectedIdentity = selectedOrDefaultContract?.identity;

  const [{ data: defaultIdentityData }] = useQuery({
    query: GET_DEFAULT_IDENTITY,
    variables: { placeId: selectedOrDefaultContract?.place?.id },
    pause: !Boolean(selectedOrDefaultContract?.place?.id),
    requestPolicy: 'network-only',
  });

  const currentPlaceRole = defaultIdentityData?.me?.topPlaceRole;
  const currentDisplayPlaceRole = defaultIdentityData?.me?.displayPlaceRole;

  const shallAcceptTerms = me?.termAccepted === false;
  const [{ data: currentTermsData }] = useQuery({
    query: GET_CURRENT_TERMS,
    pause: !shallAcceptTerms,
  });
  const currentTerm = currentTermsData?.currentTerm;

  const shallImpersonate =
    !isImpersonating && (me?.isSergicPartner || me?.isSergicPartnerAdmin);

  const handleUpdateSelectedContractIdentity = ({ identity }) => {
    setSelectedContract({
      ...selectedContract,
      identity,
    });
  };

  useEffect(() => {
    if (mainNavIsExpanded) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [mainNavIsExpanded]);

  useDeepCompareEffect(() => {
    const allUserContracts = flatten(
      me?.places?.edges?.map(({ node }) =>
        node?.currentUserContracts?.edges?.map(({ node }) => node),
      ),
    );

    if (Boolean(selectedContract)) {
      setSelectedContract(find(allUserContracts, { id: selectedContract.id }));
    } else {
      setSelectedContract(selectedOrDefaultContract);
    }
  }, [{ userData, selectedOrDefaultContract }]);

  useDeepCompareEffect(() => {
    collapseMainNav();
  }, [{ key }]);

  useEffect(() => {
    setSelectedContract(null);
  }, [isLoggedIn]);

  if (isFetchingUser && !Boolean(userData)) {
    return null;
  }

  if (userAuthError === 'no_integrated_place') {
    return (
      <AuthLayout>
        <TextRedirection>
          Votre copropriété est en cours d'intégration par Biben.
        </TextRedirection>
        <TextRedirection>
          Nous vous donnerons accès à votre espace client au plus vite. Vous
          recevrez un e-mail d’invitation prochainement.
        </TextRedirection>
      </AuthLayout>
    );
  }

  if (userAuthError === 'no_valid_place') {
    return (
      <AuthLayout>
        <TextRedirection>
          Votre copropriété n'est plus gérée par Biben, c'est pourquoi vous
          n'avez plus accès à votre espace client.
        </TextRedirection>
      </AuthLayout>
    );
  }

  return (
    <AppContext.Provider
      value={{
        selectedContract,
        selectContract: setSelectedContract,
        user: userData?.me,
        isLoadingUser,
        refetchUser,
        userPlaces: userData?.me?.places,
        selectedIdentity: selectedIdentity,
        selectedPlace: selectedContract?.place,
        groupedDiagnoses: selectedContract?.place?.diagnoses?.edges.reduce(
          (diagsMemo, { node }) => {
            (diagsMemo[node.diagnosisCategory.name] =
              diagsMemo[node.diagnosisCategory.name] || []).push(node);
            return diagsMemo;
          },
          {},
        ),
        currentPlaceRole,
        currentDisplayPlaceRole,
        currentTerm,
        shallAcceptTerms,
        shallImpersonate,
        isCoownershipBoardMember:
          currentPlaceRole === 'president_board_member' ||
          currentPlaceRole === 'coownership_board_member',
        onUpdateSelectedContractIdentity: handleUpdateSelectedContractIdentity,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
