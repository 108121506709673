import styled, { createGlobalStyle, css } from 'styled-components';
import { keys } from 'lodash';
import { media, Box, Stack, ButtonReset } from '@tymate/margaret';
import { fontStyles } from './theme';
import gagalinRegular from 'fonts/Gagalin-Regular.woff';
import montserratRegularWoff from 'fonts/Montserrat-Regular.woff';
import montserratMediumWoff from 'fonts/Montserrat-Medium.woff';
import montserratBoldWoff from 'fonts/Montserrat-Bold.woff';
import montserratRegularWoff2 from 'fonts/Montserrat-Regular.woff2';
import montserratMediumWoff2 from 'fonts/Montserrat-Medium.woff2';
import montserratBoldWoff2 from 'fonts/Montserrat-Bold.woff2';
import { Link } from 'react-router-dom';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Gagalin';
    font-style: normal;
    font-weight: 400;
    src: local('Gagalin'), local('Gagalin'), url(${gagalinRegular}) format('woff');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat'), local('Montserrat'), url(${montserratRegularWoff2}) format('woff2'), url(${montserratRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: local('Montserrat-Medium'), url(${montserratMediumWoff2}) format('woff2'), url(${montserratMediumWoff}) format('woff');
  }
                      
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat-Bold'), url(${montserratBoldWoff2}) format('woff2'), url(${montserratBoldWoff}) format('woff');
  }

  html {
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.text};
    ${fontStyles.body};
  }

  a {
    color: ${({ theme }) => theme.primary};
  }
`;

export const Title = styled(Box)`
  ${fontStyles.h1}
  font-family: ${({ theme }) => theme.fonts.title};
  letter-spacing: 0.08em;

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};
`;

export const SectionTitleBase = styled(Title).attrs({
  gutterSize: 0.5,
  as: Stack,
  margin: 0,
})`
  ${({ isHiddenOnSmallViewports }) =>
    isHiddenOnSmallViewports &&
    `
      display: none
    `};

  ${({ variant }) =>
    Boolean(variant) &&
    css`
      color: ${({ theme, variant }) => theme[variant]};
    `}

  ${media.desktop`
    display: flex;
  `}

  text-transform: uppercase;
`;

export const SectionSubtitle = styled(Title)`
  ${fontStyles.h3}
`;

export const SectionTitle = ({ variant, children, icon, ...props }) => (
  <SectionTitleBase variant={variant} {...props}>
    {icon}
    <span>{children}</span>
  </SectionTitleBase>
);

export const Subtitle = styled(Box)`
  ${fontStyles.body}
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: bold;
`;

export const Img = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

export const Grid = styled(Box)`
  display: flex;
  flex-wrap: wrap;

  ${({ gutterSize, theme }) =>
    Boolean(gutterSize) &&
    css`
      margin-top: ${theme.spacing(-1 * gutterSize)};
      margin-left: ${theme.spacing(-1 * gutterSize)};
      width: calc(100% + ${theme.spacing(gutterSize)});

      > * {
        padding-top: ${theme.spacing(gutterSize)};
        padding-left: ${theme.spacing(gutterSize)};
      }
    `};
`;

export const Cell = styled.div`
  flex: 1;
  min-width: 0;

  ${props =>
    props.sizes &&
    css`
      flex: 0 0 ${Math.floor(props.sizes.default * 10000) / 100}%;

      ${keys(props.sizes)
        .filter(key => key !== 'default')
        .map(
          breakpoint => css`
            ${media[breakpoint]`flex: 0 0 ${
              Math.floor(props.sizes[breakpoint] * 10000) / 100
            }%`};
          `,
        )};
    `};

  ${props =>
    props.min &&
    css`
      flex: 0;
      white-space: nowrap;
    `};

  ${props =>
    props.hiddenUntilTablet &&
    css`
      display: none;

      ${media.tablet`
        display: block;
      `}
    `}
`;

export const PopoverMenu = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + ${({ theme }) => theme.spacing(0.25)});
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
  min-width: 100%;
  width: 200px;
  background-color: #fff;
  max-width: calc(90vw - ${({ theme }) => theme.spacing(2)});

  ${({ size }) =>
    Boolean(size) &&
    `
      width: ${size}px
    `};

  ${({ overflow }) =>
    overflow === 'visible' &&
    `
    overflow: visible;
      `};

  ${({ alignment }) =>
    alignment === 'right' &&
    `
      left: auto;
      right: 0
      `};
`;

export const PopoverHeader = styled(Stack).attrs({
  alignY: 'center',
  paddingHorizontal: 1,
})`
  ${fontStyles.body}
  text-transform: uppercase;
  color: ${({ theme }) => theme.textLight};
  height: 40px;

  + * {
    border-top: 1px solid ${({ theme }) => theme.separator};
  }
`;

export const PopoverItemLink = styled(Stack).attrs({
  as: Link,
  gutterSize: 0.5,
  alignY: 'center',
})`
  ${fontStyles.body}
  color: inherit;
  text-decoration: none;

  svg {
    color: ${({ theme }) => theme.primary};
  }
`;

export const PopoverItem = styled(ButtonReset)`
  height: 40px;
  text-align: left;
  padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};
  transition: background-color 150ms ease;

  + * {
    border-top: 1px solid ${({ theme }) => theme.separator};
  }

  &:hover {
    background-color: ${({ theme }) => theme.background};
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
      font-weight: 500;
      background-color: ${theme.background};
    `}

  ${({ isActive, hasLabelOption, theme }) =>
    hasLabelOption &&
    isActive &&
    `
      color: ${theme.primary};
      background-color: ${theme.greenLighter};
      div{   
        :nth-child(2) {
          font-weight: normal;
          color: ${theme.gray};
         } 
    `}
    

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.gray};
      background-color: ${theme.separatorLight};
      svg {
        color: ${theme.gray};
      }
      &:hover {
        background-color: ${theme.separatorLight};
      }

      &, * {
        cursor: not-allowed;
        pointer-events: none;
      }
    `}
`;

export const BaseCard = styled(Stack).attrs(({ padding }) => ({
  direction: 'column',
  size: 'full',
  padding: padding === undefined ? 1 : padding,
}))`
  background: ${({ background }) =>
    background === undefined ? '#fff' : background};
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  text-decoration: none;

  ${({ noBorder }) =>
    noBorder &&
    `
      border: none;
      box-shadow: none;
    `};

  ${({ shadow }) =>
    shadow &&
    `
      box-shadow: 0px 15px 14px -10px rgba(0, 0, 0, 0.1);
    `};

  ${({ type }) =>
    type === 'alert' &&
    media.desktop`
    max-width: calc(50% - ${({ theme }) => theme.spacing()});
    flex: 1 1 calc(33% - ${({ theme }) => theme.spacing()});
    margin-top: ${({ theme }) => theme.spacing()};
    margin-left: ${({ theme }) => theme.spacing()};
    
    :first-child, :nth-child(4n) {
      margin-left: 0;
    }
  
    :nth-child(1), :nth-child(2), :nth-child(3) {
      margin-top: 0;
    }
  `};

  ${({ type }) =>
    type === 'message' &&
    css`
      width: fit-content;
    `};

  ${({ type, active }) =>
    type === 'amount' &&
    (active
      ? css`
          border: 1px solid rgba(179, 173, 183, 1);
          box-shadow: 0 1.5px 2px 0 rgba(0, 0, 0, 0.15);
        `
      : css`
          border: 1px solid rgba(0, 0, 0, 0.08);
          box-shadow: 0 1.5px 2px 0 rgba(0, 0, 0, 0.15);
        `)};

  ${media.tablet`
    padding: ${({ theme, padding }) =>
      theme.spacing(padding === undefined ? 1.5 : padding)};
    border-radius: ${({ radius }) => (radius ? radius : '3px')};
  `}

  ${({ direction }) =>
    direction === 'row' &&
    `
    direction: 'row',
      `};
`;

export const SmallViewportsContainer = styled(Box)`
  ${
    '' /* padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()}; */
  }

  ${media.tablet`
    padding: 0;
  `}
`;

// 👇 Below element is used to prevent broken layout with
//    ellipses on flex children.
//    (See https://css-tricks.com/flexbox-truncated-text/)
export const FlexTruncatedText = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      color: ${({ theme }) => theme.greyLight};
    `}
`;

export const TextOnBackground = styled(Box)`
  padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()} 0;

  ${media.tablet`
    padding: 0;
  `}
`;

export const Legend = styled.div`
  ${fontStyles.legend};
  color: ${({ theme }) => theme.primaryDark};
  transition: color 150ms ease;
`;

export const ShortLegend = styled.div`
  ${fontStyles.legend};
  color: ${({ theme }) => theme.primaryDark};
  transition: color 150ms ease;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ModalTitle = styled(Box).attrs(({ variant }) => ({
  paddingBottom: variant === 'dropdown' ? 0.25 : 1,
}))`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLight};
  text-transform: uppercase;
`;

export const Hr = styled.hr`
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.separator};
  width: 100%;
  margin-bottom: 0;
`;

export const Radios = styled(BaseCard)`
  ${fontStyles.bodySmall}
  direction: column;
  border: 1px solid ${({ theme }) => theme.separator};
  background-color: #ffffff;
  padding: 0;
`;

export const AmountValue = styled.div`
  ${({ variant }) =>
    variant === 'credit' &&
    css`
      color: ${({ theme }) => theme.blueDark};
    `}
  ${({ variant }) =>
    variant === 'debit' &&
    css`
      color: ${({ theme }) => theme.pink};
    `};
`;

export const DownloadLink = styled.a`
  color: ${({ theme }) => theme.greyMid};
  text-decoration: underline;
`;

export const SeeMoreButton = styled(ButtonReset)`
  color: ${({ theme }) => theme.greyLight};

  &:hover {
    color: ${({ theme }) => theme.primaryDark};
  }
`;

export const RequiredField = styled.span`
  color: ${({ theme }) => theme.required};
  margin-right: 0.2rem;
`;

export const DetailField = styled.span`
  color: ${({ theme }) => theme.gray};
`;

export const HighLightField = styled.span`
  color: ${({ theme }) => theme.primary};
`;

export const NoticeTag = styled.div`
  background-color: ${({ theme }) => theme.greenLighter};
  padding: 1rem;
  width: 100%;
`;

export const BasicTextBold = styled.div`
  font-weight: 700;
`;

export const LoadingLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
