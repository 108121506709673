import gql from 'graphql-tag';

export const GET_ALL_CONVERSATIONS = gql`
  query allConversations {
    allConversations {
      edges {
        node {
          id
          read
          displayName
          updatedAt
          preview
          members(first: 2) {
            totalCount
            pageInfo {
              hasNextPage
            }
            edges {
              node {
                id
                displayName
              }
            }
          }
          author {
            email
            firstName
            lastName
            displayName
            avatarUrl
          }
        }
      }
    }
  }
`;

export const GET_CONVERSATION_MESSAGES = gql`
  query conversationMessages($conversationId: ID!, $cursor: String) {
    conversationMessages(conversationId: $conversationId, after: $cursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          body
          createdAt
          updatedAt
          attachments {
            nodes {
              id
              fileUrl
              sourceFileName
              sourceFileSize
              sourceContentType
              displayName
            }
          }
          user {
            id
            avatarUrl
            displayName
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_CONVERSATION = gql`
  query conversation($conversationId: ID!) {
    conversation(id: $conversationId) {
      id
      displayName
      preview
      members(first: 2) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            displayName
          }
        }
      }
    }
  }
`;

export const GET_CONVERSATION_META = gql`
  query conversationWithMeta($conversationId: ID!) {
    conversation(id: $conversationId) {
      id
      displayName
      members {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            displayName
          }
        }
      }
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation createConversation($input: CreateConversationInput!) {
    createConversation(input: $input) {
      errors {
        key
        message
      }
      conversation {
        id
      }
    }
  }
`;

export const UPDATE_CONVERSATION = gql`
  mutation updateConversation($input: UpdateConversationInput!) {
    updateConversation(input: $input) {
      errors {
        key
        message
      }
      conversation {
        id
        displayName
        members {
          edges {
            node {
              id
              displayName
              avatarUrl
            }
          }
        }
      }
    }
  }
`;

export const QUIT_CONVERSATION = gql`
  mutation quitConversation($input: QuitConversationInput!) {
    quitConversation(input: $input) {
      errors {
        key
        message
      }
      conversation {
        id
      }
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation createMessage($input: CreateMessageInput!, $placeId: ID!) {
    createMessage(input: $input) {
      errors {
        key
        message
      }
      message {
        id
        body
        user {
          id
          avatarUrl
          displayName
          firstName
          lastName
          displayPlaceRole(placeId: $placeId)
        }
        attachments {
          nodes {
            id
            displayName
            fileUrl
            sourceFileName
            sourceFileSize
            sourceContentType
          }
        }
      }
    }
  }
`;
