import styled, { css } from 'styled-components';
import { Box, media, ButtonReset } from '@tymate/margaret';

export const Layout = styled.div`
  height: calc(100vh - 64px - 4rem - 98px - 16px);

  ${media.tablet`
  display: grid;
  grid-template-columns: 300px 1fr;
`};
`;

export const Aside = styled(Box)`
  height: 100%;

  ${({ shouldBeHiddenOnSmallViewports }) =>
    shouldBeHiddenOnSmallViewports &&
    css`
      display: none;

      ${media.tablet`
      display: block
    `};
    `}
`;

export const Content = styled.div`
  height: 100%;
`;

export const BackButton = styled(ButtonReset)`
  display: none;
  ${({ shouldBeShownOnSmallViewports }) =>
    shouldBeShownOnSmallViewports &&
    css`
      display: block;
      padding: ${({ theme }) => theme.spacing()};

      ${media.tablet`
        display: none;
      `};
    `};
`;
